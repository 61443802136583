@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-y: scroll;
}

body {
  font-family: 'Inter';
  margin: 0;
  padding: 0;
}

@keyframes fly {
  0% {
    transform: translate(var(--startX), var(--startY)) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translate(var(--endX), var(--endY)) rotate(var(--rotation));
    opacity: 0;
  }
}
